import React, { useState, useRef } from "react"
import dynamic from "next/dynamic"
import useOutsideClick from "../useOutsideClick"

const ActiveLink = dynamic(() => import("../buttons/active-link"), {
  ssr: true,
})

function SubItemsNavigation({ item, classList }) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef()

  useOutsideClick(dropdownRef, () => {
    setIsOpen(false)
  })

  const openDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <li
      className="mb-0 relative flex items-center"
      onMouseOver={() => setIsOpen(true)}
      onFocus={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
      onBlur={() => setIsOpen(false)}
      data-layer-menu_item={item.name}
    >
      <ActiveLink
        href={`${item.slugUrl}`}
        classList="transition-all duration-300 text-xs pl-4 pt-4 pb-3 lg:pt-8 lg:pb-7 lg:py-8 uppercase hover:text-pink-500"
      >
        {item.name}
      </ActiveLink>
      <button
        className="p-2"
        type="button"
        onClick={openDropdown}
        ref={dropdownRef}
        aria-label="dropdown"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`fill-current text-ocean-500 w-3 h-2 transform transition-all duration-150 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          viewBox="0 0 12 9"
        >
          <path fill="#64C3BE" d="M6 9L.8 0h10.4L6 9z" />
        </svg>
      </button>
      {/* {isOpen && ()} */}
      <ul
        className={`${classList} shadow-md z-10 w-full min-w-200px bg-gray-400 transition-all duration-300 transform px-4 py-2 border-t-3px border-pink-500 ${
          isOpen ? "visible opacity-100" : "invisible opacity-0"
        }`}
      >
        {item.itemsCollection.items.map(subitem => (
          <li
            key={subitem.name}
            className="mb-0 w-full border-b border-metal-900 last:border-0"
            data-layer-menu_item={subitem.name}
          >
            <ActiveLink
              href={`${subitem.slugUrl}`}
              classList="transition-all duration-300 text-xxs text-black px-2 pb-2 pt-3 first:pl-0 last:pr-0 uppercase block hover:text-pink-500"
            >
              {subitem.name}
            </ActiveLink>
          </li>
        ))}
      </ul>
    </li>
  )
}

export default SubItemsNavigation
