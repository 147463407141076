import React from "react"
import dynamic from "next/dynamic"

import { useRouter } from 'next/router';

const ButtonTriangleTracking = dynamic(
  () => import("../buttons/button-triangle-tracking"),
  { ssr: true }
)
const Hamburger = dynamic(() => import("./hamburguer"), { ssr: true })
const NavigationDrawer = dynamic(() => import("./navigation-drawer"), {
  ssr: true,
})
const IconType = dynamic(() => import("../icons/icons-group"), { ssr: true })

function MobileNavigation({
  isHamburgerOpen,
  openHamburger,
  hamburgerRef,
  drawerRef,
  main,
  headerButtons,
  setIsHamburgerOpen,
  isDesktop,
  pageProps
}) {

  const router = useRouter();
  const { location } = pageProps;

  let contentfulButton;
  let urlButton;
  let placeholderButton;

  if (router.route === '/find/[slug]' && location && location.total > 0) {
    contentfulButton = false
    placeholderButton = null
    urlButton = 
      location
      && location.restaurants
      && location.restaurants[0] 
      && location.restaurants[0].status
      && location.restaurants[0].status === "Active/Open" || location
      && location.restaurants[0].status === "Just Hatched" || location
      && pageProps 
      && pageProps.location 
      && pageProps.location.restaurants 
      && pageProps.location.restaurants.length > 0 
      && pageProps.location.restaurants[0] 
      && pageProps.location.restaurants[0].orderOnlineUrl 
    ? 
      pageProps.location.restaurants[0].orderOnlineUrl 
    : null
  } else if (router.route === '/catering') {
    contentfulButton = false
    placeholderButton = "Order Catering"
    urlButton = process.env.NEXT_PUBLIC_COUNTRY === "us" ? 'https://catering.nandosperiperi.com/' : 'https://catering.nandos.ca/'
  } else {
    contentfulButton = true
    placeholderButton = null
    urlButton = null
  }

  return (
    <>
      <nav className="w-4/12 pl-8">
        <div className="flex flex-wrap items-center justify-start">
          <div className="-ml-3 flex items-center justify-start flex-wrap">
            <IconType type="nandos-logo-mobile" classList="w-5 h-10" url="/" />
            <div className="flex items-center pl-3">
              <Hamburger
                isHamburgerOpen={isHamburgerOpen}
                openHamburger={() => {
                  openHamburger()
                }}
                classList="w-auto"
                hamburgerRef={hamburgerRef}
              />
            </div>
          </div>
        </div>
      </nav>
      <div className="flex flex-wrap items-center justify-end w-8/12 pl-8">
        <div className="flex items-center justify-end">
          {headerButtons.items.map(button => (
            <React.Fragment key={button.name}>
              {button.name === "Order Online" ? (
                <ButtonTriangleTracking
                  triangle
                  href={contentfulButton || !urlButton ? `${button.slugUrl}/` : `${urlButton}`}
                  openInNewTab
                  classList="btn-yellow"
                  trackingEvent="TopNavigationClick"
                  color={button.buttonColor}
                >
                  {contentfulButton || !placeholderButton ? button.name : placeholderButton}
                </ButtonTriangleTracking>
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </div>
      <NavigationDrawer
        classList="absolute top-full inset-x-8 mt-4 w-auto min-w-auto"
        items={main.items}
        isHamburgerOpen={isHamburgerOpen}
        hamburgerRef={hamburgerRef}
        drawerRef={drawerRef}
        setIsHamburgerOpen={setIsHamburgerOpen}
        headerButtons={headerButtons}
        isDesktop={isDesktop}
      />
    </>
  )
}

export default MobileNavigation
