import React, { useState, useEffect, useCallback } from "react"
import dynamic from "next/dynamic"
import { useRouter } from 'next/router';

import SubItemsNavigation from "./sub-items-navigation"

const ButtonTriangleTracking = dynamic(
  () => import("../buttons/button-triangle-tracking"),
  { ssr: false }
)
const ActiveLink = dynamic(() => import("../buttons/active-link"), {
  ssr: true,
})
const Hamburger = dynamic(() => import("./hamburguer"), { ssr: false })
const NavigationDrawer = dynamic(() => import("./navigation-drawer"), {
  ssr: true,
})
const IconType = dynamic(() => import("../icons/icons-group"), { ssr: false })
const LoginButton = dynamic(() => import("../buttons/login-button"), {
  ssr: false,
})

function DesktopNavigation({
  main,
  headerButtons,
  isHamburgerOpen,
  openHamburger,
  hamburgerRef,
  drawerRef,
  setIsHamburgerOpen,
  pageProps
}) {
  const [alternativeNav, setAlternativeNav] = useState([])
  const [mainNav, setMainNav] = useState([])
  const router = useRouter();
  const { location } = pageProps;

  let contentfulButton;
  let urlButton;
  let placeholderButton;

  if (router.route === '/find/[slug]' && location && location.total > 0) {
    contentfulButton = false
    placeholderButton = null
    urlButton = 
      location
      && location.restaurants
      && location.restaurants[0] 
      && location.restaurants[0].status
      && location.restaurants[0].status === "Active/Open" || location
      && location.restaurants[0].status === "Just Hatched" || location
      && pageProps 
      && pageProps.location 
      && pageProps.location.restaurants 
      && pageProps.location.restaurants.length > 0 
      && pageProps.location.restaurants[0] 
      && pageProps.location.restaurants[0].orderOnlineUrl 
    ? 
      pageProps.location.restaurants[0].orderOnlineUrl 
    : null
  } else if (router.route === '/catering') {
    contentfulButton = false
    placeholderButton = "Order Catering"
    urlButton = process.env.NEXT_PUBLIC_COUNTRY === "us" ? 'https://catering.nandosperiperi.com/' : 'https://catering.nandos.ca/'
  } else {
    contentfulButton = true
    placeholderButton = null
    urlButton = null
  }

  // View elements navigation on resize
  // TO DO - DINAMICALLY ON RESIZE
  const viewNavElements = useCallback(() => {
    if (window.innerWidth >= 1200) {
      setAlternativeNav(main.items.slice(5))
      setMainNav(main.items.slice(0, 5))
    } else {
      setAlternativeNav(main.items.slice(4))
      setMainNav(main.items.slice(0, 4))
    }
  }, [main.items])

  useEffect(() => {
    const resizeWindow = () => {
      viewNavElements()
    }
    viewNavElements()
    window.addEventListener("resize", resizeWindow)

    return () => {
      setAlternativeNav([])
      setMainNav([])
      window.removeEventListener("resize", resizeWindow)
    }
  }, [viewNavElements])

  return (
    <>
      <nav className="flex flex-wrap items-center w-5/12 pl-8">
        <ul className="flex items-stretch whitespace-nowrap w-11/12 pr-8 -ml-4">
          {mainNav.map(item => (
            <React.Fragment key={item.name}>
              {item.itemsCollection.items.length > 0 ? (
                <SubItemsNavigation
                  classList="absolute top-full left-0"
                  item={item}
                  dataLayer={item.name}
                />
              ) : (
                <li
                  className="pl-4 mb-0 relative"
                  data-layer-menu_item={item.name}
                >
                  <ActiveLink
                    href={`${item.slugUrl}`}
                    classList="transition-all duration-300 text-xs px-4 pt-4 pb-3 lg:pt-8 lg:pb-7 first:pl-0 last:pr-0 uppercase hover:text-pink-500 h-full flex items-center"
                  >
                    {item.name}
                  </ActiveLink>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>

        {alternativeNav.length > 0 && (
          <div className="relative flex items-center">
            <Hamburger
              isHamburgerOpen={isHamburgerOpen}
              openHamburger={openHamburger}
              classList="w-1/12 pt-4 pb-4 lg:pt-10 lg:pb-10"
              hamburgerRef={hamburgerRef}
            />
            <NavigationDrawer
              classList="absolute top-full left-0 w-full min-w-300px"
              items={alternativeNav}
              isHamburgerOpen={isHamburgerOpen}
              hamburgerRef={hamburgerRef}
              drawerRef={drawerRef}
              setIsHamburgerOpen={setIsHamburgerOpen}
            />
          </div>
        )}
      </nav>
      <div className="flex flex-wrap items-center w-2/12 pl-8">
        <div className="pl-4 w-full">
          <IconType
            type="nandos-logo"
            classList="w-full max-w-120px h-16"
            url="/"
          />
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-end w-5/12 pl-8">
        <div className="flex items-center justify-end flex-row-reverse">
          {headerButtons.items.map((button, index) => (
            <React.Fragment key={button.name}>
              {index === 0 ? (
                <ButtonTriangleTracking
                  triangle
                  href={contentfulButton || !urlButton ? `${button.slugUrl}` : `${urlButton}`}
                  openInNewTab
                  notHaveWrapper
                  classList="btn-yellow"
                  trackingEvent="TopNavigationClick"
                  color={button.buttonColor}
                >
                  {contentfulButton || !placeholderButton ? button.name : placeholderButton}
                </ButtonTriangleTracking>
              ) : (
                <LoginButton button={button} />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  )
}

export default DesktopNavigation
